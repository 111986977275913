import { useDispatch, useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import Modal from "../modal.component";
import { tocReasonDropdownHeading, tocReasonHeading, tocReasonSubHeading } from "./toc-reason-modal.constant";
import Button from "components/Button/button.component";
import { TocReasonModalPresentationProps } from "./toc-reason-modal.props";
import { getTOCManagementState, setTocCustomiseReason } from "state/feature/toc-management/toc-management.slice";
import Checkbox from "components/Checkbox/checkbox.component";
import { ICustomiseReason } from "state/types/toc-management-slice.type";

const TocReasonModalPresentation = (props: TocReasonModalPresentationProps) => {
  const { modal } = useSelector(getCommonState);
  const { tocCustomiseReason, customiseReasons } = useSelector(getTOCManagementState);
  const dispatch = useDispatch();
  const { closeTocReasonModal, handleSubmit, showError, handleCustomiseError } = props;
  return (
    <Modal isOpen={modal.isOpenTocReasonModal} className="center toc-reason-modal">
      <div className="header">{tocReasonHeading}</div>
      <div className="toc-reason-content">
        <div className="toc-reason-content-sub-heading">{tocReasonSubHeading}</div>
      </div>
      <div className="toc-reasons-checkbox-container">
        {customiseReasons.map((reason) => {
          const isChecked = tocCustomiseReason.find((res) => res.key === reason.key) ? true : false;
          return (
            <Checkbox
              labelContainerClassname="customise-reason-checkbox"
              id={reason.key}
              value={reason.name}
              name={reason.key}
              isChecked={isChecked}
              onChange={(e) => {
                handleCustomiseError(false);
                if (!isChecked) {
                  const arr: Array<ICustomiseReason> = [...tocCustomiseReason, reason];
                  dispatch(setTocCustomiseReason(arr));
                } else {
                  const arr = tocCustomiseReason.filter((res) => res.key !== reason.key);
                  dispatch(setTocCustomiseReason(arr));
                }
              }}
            />
          );
        })}
      </div>
      <div className="button-section">
        <div>
          <Button text="Cancel" className="white-button cancel-button" onClick={closeTocReasonModal} />
          <Button
            text="Submit"
            className="green-button submit-button"
            onClick={handleSubmit}
            disabled={tocCustomiseReason.length === 0}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TocReasonModalPresentation;
