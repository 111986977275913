import "./transition-of-care.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  getBrowserState,
  getCurrentPageTaskState,
  getTaskManagementState,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
  setLoadTaskList,
  setShouldPerformPostSubmitAction,
} from "state/feature/task-management/task-management.slice";
import moment from "moment";

import TocRevision from "./toc-revisions/toc-revision.component";
import { PAC_TYPE, RevisionReasonModalEnum, TocStatusEnum } from "./constants/index.constant";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  getTOCManagementState,
  addNewTocRevision,
  updateTocList,
  resetTocState,
  resetTocEditForm,
  setCarePlanData,
  setIsTocValueChanged,
  setTocEditForm,
  getTocEditForm,
  setIsTocsLoading,
  setIsCustomisedCarePlanEnabled,
  setCustomiseReasonForSelectedToc,
} from "state/feature/toc-management/toc-management.slice";
import {
  ICreateNewTocPayload,
  ICreateTransitionOfCareItem,
  IEditTocPayload,
  IEditTransitionOfCareItem,
  ITocRevisionReason,
  ITransitionOfCare,
} from "state/types/toc-management-slice.type";
import {
  getTocAsync,
  getIntakeAsync,
  getTocRevisionReasons,
  getTocStatuses,
  getIntakeDetails,
  createTocAsync,
  editTocAsync,
  getCarePlans,
  getTocRiskLevel,
  getPacTypes,
  getCustomiseReasons,
} from "state/feature/toc-management/toc-management.action";
import { useAppDispatch } from "state/store";
import { getCommonState, setIsOpenAddServiceModal } from "state/feature/common/common.slice";
import useFacilities from "./hooks/useFacilities";
import { IRevisionReasonModal } from "./modals/revision-reason-modal/props.type";
import useUrlBlocking from "shared/hooks/use-blocking.hook";
import TransitionOfCarePresentation from "./transition-of-care.component";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";
import { toast } from "react-toastify";
import { CarePlanServices } from "shared/enums/care-plan-services.enum";

const TransitionOfCareContainer = () => {
  useUrlBlocking();
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { currentTab } = useSelector(getBrowserState);
  const { patientDetails, isValidIntake, isValidatingIntake, intakeId, taskId, taskPayload } = useSelector(
    getCurrentPageTaskState(currentTab!.taskId)
  );
  const [selectedToc, setSelectedToc] = useState<ITransitionOfCare | null>(null);
  const [selectedCarePlan, setSelectedCarePlan] = useState("");
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const [reasonModalPayload, setReasonModalPayload] = useState<IRevisionReasonModal | null>();
  const {
    isTocsLoading,
    tocs,
    intake,
    facilities,
    revisionReasons,
    isTocValueChanged,
    statuses,
    carePlanData,
    allFacilities,
    originalCarePlanData,
    tocCustomiseReason,
  } = useSelector(getTOCManagementState);
  const { redirectParams } = useSelector(getTaskManagementState);
  const tocEditForm = useSelector(getTocEditForm);
  const { featureFlags } = useSelector(getCommonState);
  const [isAllTocsApproved, setIsAllTocsApproved] = useState(true);
  const [isNewTocExists, setIsNewTocExists] = useState(false);
  const [revisionReason, setRevisionReason] = useState<ITocRevisionReason | null>();
  const [customiseCarePlan, setCustomiseCarePlan] = useState(false);
  const ref = useRef<any>(null);
  const { getAchFacilitiesWithDistance, getAllFacilitiesWithDistance } = useFacilities(intakeId);
  const prepareNewTocRevision = useCallback(() => {
    const version = tocs.length;
    const { id } = revisionReason || {};
    return {
      id: "",
      intakeId: parseInt(intakeId, 10),
      noteCareManager: "",
      version: version + 1,
      anticipatedAcuteLOS: 0,
      createdAt: moment().format("MM/DD/YYYY"),
      approvedAt: "",
      clientId: intake?.clientId,
      isExpanded: true,
      status: TocStatusEnum.NEW,
      reasonId: id,
      standardCarePlanID: null,
      customizeReasonId: null,
      isCustomised: version === 0 || tocs.length === 0 ? false : true,
    } as ITransitionOfCare;
  }, [intakeId, intake, tocs.length, revisionReason]);

  useEffect(() => {
    if (facilities.length) {
      getAchFacilitiesWithDistance();
    }
    if (allFacilities.length) {
      getAllFacilitiesWithDistance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities.length, allFacilities.length]);

  useEffect(() => {
    dispatch(getTocRiskLevel(intakeId));
  }, [dispatch, intakeId]);

  useEffect(() => {
    dispatch(setIsCurrentTaskTouched({ taskId, isTouched: isTocValueChanged }));
    if (isTocValueChanged) {
      dispatch(setIsCurrentTaskDirty({ taskId, isDirty: true }));
    }
  }, [isTocValueChanged, taskId, dispatch]);

  useEffect(() => {
    if (intakeId && isValidIntake && !isValidatingIntake && !taskPayload) {
      appDispatch(getIntakeAsync(intakeId)).then((data: any) => {
        dispatch(getIntakeDetails(intakeId));
        dispatch(getCarePlans(data.payload.episodeId));
      });
      dispatch(getTocRevisionReasons());
      dispatch(getTocStatuses());
    }
  }, [dispatch, appDispatch, intakeId, isValidIntake, isValidatingIntake, taskPayload]);

  useEffect(() => {
    if (intakeId && isValidIntake && !isValidatingIntake && !taskPayload) {
      appDispatch(getTocAsync(intakeId));
    }
  }, [dispatch, appDispatch, intakeId, isValidIntake, isValidatingIntake, taskPayload]);

  useEffect(() => {
    if (!isTocsLoading) {
      const isApproved = tocs.findIndex((el) => el.status === TocStatusEnum.PENDING) === -1;
      setIsAllTocsApproved(isApproved);

      const isNewTocPresent = tocs.findIndex((el) => el.status === TocStatusEnum.NEW) > -1;
      setIsNewTocExists(isNewTocPresent);
    }
  }, [tocs, intakeId, isTocsLoading, dispatch]);

  useEffect(() => {
    if (!isTocsLoading && tocs.length === 0 && intake && isAllTocsApproved) {
      dispatch(addNewTocRevision(prepareNewTocRevision()));
    }
  }, [tocs, intake, isTocsLoading, isAllTocsApproved, prepareNewTocRevision, dispatch]);

  const updateState = () => {
    const InfiniteScrollInnerDivElement = document.querySelector(".revision-table-body");
    if (InfiniteScrollInnerDivElement) {
      setHasScrollBar(InfiniteScrollInnerDivElement.scrollHeight > InfiniteScrollInnerDivElement.clientHeight);
    }
  };

  useEffect(() => {
    if (!taskPayload) {
      dispatch(getPacTypes());
      dispatch(getCustomiseReasons());
    }
  }, [dispatch, taskPayload]);

  useEffect(() => {
    if (tocs.length > 0 && !isTocsLoading) {
      setTimeout(() => {
        updateState();
      }, 1000);
      updateState();
    } else {
      updateState();
    }
    window.addEventListener("resize", updateState);
    return () => window.removeEventListener("resize", updateState);
  }, [tocs, isTocsLoading]);

  useEffect(() => {
    return () => {
      dispatch(setIsOpenAddServiceModal(false));
      dispatch(resetTocState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (revisionReason) {
      if (!isAllTocsApproved) {
        const pendingToc = tocs.find((el) => el.status === TocStatusEnum.PENDING);

        if (pendingToc) {
          const updatedTocs = tocs.map((toc) => {
            if (toc.id === pendingToc?.id) {
              return { ...toc, status: TocStatusEnum.CANCELLED };
            }
            return { ...toc };
          });

          dispatch(updateTocList(updatedTocs));
        }
      }

      dispatch(resetTocEditForm());
      dispatch(addNewTocRevision(prepareNewTocRevision()));
      setRevisionReason(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prepareNewTocRevision, revisionReason]);

  const openReasonModal = () => {
    setReasonModalPayload({
      isOpen: true,
      src: isAllTocsApproved ? RevisionReasonModalEnum.APPROVED_REVISION : RevisionReasonModalEnum.PENDING_REVISION,
      menuItems: revisionReasons,
      onCancel: () => {
        setReasonModalPayload({ ...reasonModalPayload, isOpen: false } as IRevisionReasonModal);
      },
      onCreate: (val: ITocRevisionReason | null) => {
        if (val) {
          dispatch(setCarePlanData([]));
          setRevisionReason(val);
          setReasonModalPayload({ ...reasonModalPayload, isOpen: false } as IRevisionReasonModal);
          const revisionBody = document.querySelector(".revision-table-body");
          if (revisionBody) {
            revisionBody.scrollTo({
              top: 0,
              left: 0,
            });
          }
        }
      },
      onModalClose: () => {
        setReasonModalPayload({ ...reasonModalPayload, isOpen: false } as IRevisionReasonModal);
      },
    });
  };

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return (
        <>
          <PatientDetailsHeader patientDetails={patientDetails} intakeId={intakeId} />
        </>
      );
    }
  }, [patientDetails, intakeId]);

  const onClickCustomisedCarePlan = () => {
    dispatch(setCustomiseReasonForSelectedToc(tocCustomiseReason.map((reason) => reason.key).join(",")));
    dispatch(setIsCustomisedCarePlanEnabled(true));
    setCustomiseCarePlan(true);
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        isTocItemsFormDirty: true,
        facilityError: "",
        losError: "",
      })
    );
  };

  const submitNewToc = () => {
    if (selectedToc) {
      dispatch(setTocEditForm({ ...tocEditForm, isSubmitModalOpen: false }));
      const pendingStatus = statuses.find((statusItem) => statusItem.status === TocStatusEnum.PENDING);

      const payload = {
        intakeId: selectedToc.intakeId,
        taskId: currentTab?.taskId,
        clientId: selectedToc.clientId,
        anticipatedAcuteLOS: carePlanData.find((carePlan) => carePlan.longName === CarePlanServices.ACUTE)?.quantity,
        noteCareManager: tocEditForm.navigatorNotes ? tocEditForm.navigatorNotes.trim() : null,
        additionalNote: tocEditForm.additionalNotes ? tocEditForm.additionalNotes.trim() : null,
        version: selectedToc.version,
        reasonId: selectedToc.reasonId,
        statusId: pendingStatus?.id,
        standardCarePlanId: parseInt(selectedCarePlan),
        approverId: "",
        isCustomised: tocs.length > 1 ? true : customiseCarePlan,
        customizeReasonId: tocCustomiseReason.map((reason) => reason.key).join(","),
      } as ICreateNewTocPayload;
      const newTocItems: ICreateTransitionOfCareItem[] = [];

      if (tocEditForm.isHomeWithNoServicesEnabled) {
        carePlanData.forEach((carePlan, index: number) => {
          if (carePlan.longName === CarePlanServices.ACUTE) {
            const provider = facilities.find((facility) => facility.id.toString() === carePlan.providerId.toString());
            const tocItem = {
              pacTypeName: carePlan.longName,
              providerID: provider ? parseInt(provider.id.toString()) : -1,
              targetLOS: carePlan.quantity,
              admission: "after previous",
              sortOrder: index,
              pacTypeId: carePlan.pacTypeId,
              longName: carePlan.longName,
              quantity: carePlan.quantity,
            } as ICreateTransitionOfCareItem;
            newTocItems.push(tocItem);
            newTocItems.push({
              pacTypeName: PAC_TYPE.HOME_SERVICE,
              providerID: 0,
              targetLOS: 0,
              sortOrder: 0,
              pacTypeId: 0,
              longName: PAC_TYPE.HOME_SERVICE,
              quantity: 0,
              admission: "",
            });
          }
        });
      } else {
        carePlanData.forEach((carePlan, index: number) => {
          let provider = null;
          if (carePlan.longName !== CarePlanServices.ACUTE) {
            provider = allFacilities.find((facility) => facility.id.toString() === carePlan.providerId.toString());
          } else {
            provider = facilities.find((facility) => facility.id.toString() === carePlan.providerId.toString());
          }
          const newItem: ICreateTransitionOfCareItem = {
            pacTypeName: carePlan.longName,
            longName: carePlan.longName,
            providerID: provider ? parseInt(provider.id.toString()) : -1,
            targetLOS: carePlan.quantity,
            quantity: carePlan.quantity,
            admission: "after previous",
            sortOrder: index,
            pacTypeId: carePlan.pacTypeId,
          };
          newTocItems.push(newItem);
        });
      }
      payload.transitionOfCareItems = newTocItems;
      dispatch(setIsTocsLoading(true));
      appDispatch(createTocAsync(payload))
        .unwrap()
        .then(() => {
          dispatch(setIsTocValueChanged(false));
          dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
          dispatch(setLoadTaskList(true));
          dispatch(getTocAsync(intakeId.toString()));
          dispatch(resetTocEditForm());
          if (redirectParams) {
            toast.success("Care Plan added successfully", {
              containerId: "main",
            });
          } else {
            appDispatch(setShouldPerformPostSubmitAction({ taskId, performAction: true }));
          }
        });
    }
  };

  const editToc = () => {
    if (selectedToc) {
      dispatch(setTocEditForm({ ...tocEditForm, isSubmitModalOpen: false }));
      const payload = {
        id: selectedToc.id,
        version: selectedToc.version,
        anticipatedAcuteLOS: carePlanData.find((carePlan) => carePlan.longName === CarePlanServices.ACUTE)?.quantity,
        noteCareManager: tocEditForm.navigatorNotes,
        additionalNote: tocEditForm.additionalNotes ? tocEditForm.additionalNotes.trim() : null,
        statusId: selectedToc.statusId,
        isCustomised: customiseCarePlan || selectedToc.isCustomised,
        standardCarePlanId: parseInt(selectedCarePlan),
        ...(tocCustomiseReason.length > 0 && {
          customizeReasonId: tocCustomiseReason.map((reason) => reason.key).join(","),
        }),
      } as IEditTocPayload;

      const updateTocItems: IEditTransitionOfCareItem[] = [];

      if (tocEditForm.isHomeWithNoServicesEnabled) {
        const existingTocItem = selectedToc.transitionOfCareItems?.find(
          (el) => el.pacTypeName === CarePlanServices.ACUTE
        );
        carePlanData.forEach((carePlan, index: number) => {
          if (carePlan.longName === CarePlanServices.ACUTE) {
            const provider = allFacilities.find(
              (facility) => facility.id.toString() === carePlan.providerId.toString()
            );

            const tocItem = {
              pacTypeName: carePlan.longName,
              providerID: provider ? parseInt(provider.id.toString()) : -1,
              targetLOS: carePlan.quantity,
              sortOrder: index,
              pacTypeId: carePlan.pacTypeId,
              longName: carePlan.longName,
              quantity: carePlan.quantity,
              id: existingTocItem ? existingTocItem.id : -1,
            } as IEditTransitionOfCareItem;
            updateTocItems.push(tocItem);
            updateTocItems.push({
              pacTypeName: PAC_TYPE.HOME_SERVICE,
              providerID: 0,
              targetLOS: 0,
              sortOrder: 0,
              pacTypeId: 0,
              longName: PAC_TYPE.HOME_SERVICE,
              quantity: 0,
              id: "-1",
            });
          }
        });
      } else {
        carePlanData.forEach((carePlan, index: number) => {
          const provider = allFacilities.find((facility) => facility.id.toString() === carePlan.providerId.toString());
          const newItem: IEditTransitionOfCareItem = {
            pacTypeName: carePlan.longName,
            providerID: provider ? parseInt(provider.id.toString()) : -1,
            targetLOS: carePlan.quantity,
            sortOrder: index,
            pacTypeId: carePlan.pacTypeId,
            id: !carePlan.id ? "-1" : carePlan.id,
            longName: carePlan.longName,
            quantity: carePlan.quantity,
          };
          updateTocItems.push(newItem);
        });
      }

      payload.transitionOfCareItems = updateTocItems;
      dispatch(setIsTocsLoading(true));
      appDispatch(editTocAsync(payload))
        .unwrap()
        .then(() => {
          dispatch(setIsTocValueChanged(false));
          dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
          dispatch(setLoadTaskList(true));
          dispatch(getTocAsync(intakeId.toString()));
          dispatch(resetTocEditForm());
        });
    }
  };

  const handleSubmitClick = () => {
    dispatch(setIsTocValueChanged(false));
    dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
    if (selectedToc) {
      if (selectedToc.id) {
        editToc();
      } else {
        submitNewToc();
      }
    }
  };

  const handleSelectedToc = (toc: ITransitionOfCare) => {
    setSelectedToc(toc);
  };

  const handleSelectedCarePlan = (id: string) => {
    setSelectedCarePlan(id);
  };

  const getTocsList = () =>
    tocs.map((toc) => {
      let tocItems;
      if (toc.version > 1 && toc.status === TocStatusEnum.NEW) {
        tocItems = {
          ...toc,
          transitionOfCareItems: tocs[toc.version - (toc.version - 1)].transitionOfCareItems,
          anticipatedAcuteLOS: tocs[toc.version - (toc.version - 1)].anticipatedAcuteLOS,
        };
      } else {
        tocItems = toc;
      }
      return (
        <TocRevision
          toc={tocItems}
          key={toc.version}
          taskId={taskId}
          handleSelectedToc={handleSelectedToc}
          handleSelectedCarePlan={handleSelectedCarePlan}
          copyOfSelectedCarePlan={selectedCarePlan}
          customiseCarePlan={customiseCarePlan}
        />
      );
    });

  const resetFormDetails = () => {
    if (selectedToc?.status === TocStatusEnum.NEW) {
      dispatch(setIsTocValueChanged(false));
      setCustomiseCarePlan(false);
      dispatch(setIsCustomisedCarePlanEnabled(false));
      dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
      dispatch(
        setTocEditForm({
          ...tocEditForm,
          navigatorNotes: selectedToc?.noteCareManager,
          isCancelModalOpen: false,
          isTocItemsFormDirty: false,
          isFormEmpty: true,
          isHomeWithNoServicesEnabled: false,
          facilityError: "",
          additionalNotes: "",
        })
      );
      dispatch(setCarePlanData([]));
      setSelectedCarePlan("-2");
    } else {
      dispatch(setIsTocValueChanged(false));
      setCustomiseCarePlan(false);
      dispatch(setIsCustomisedCarePlanEnabled(false));
      dispatch(setIsCurrentTaskDirty({ taskId, isDirty: false }));
      dispatch(
        setTocEditForm({
          ...tocEditForm,
          navigatorNotes: selectedToc?.noteCareManager,
          isCancelModalOpen: false,
          isTocItemsFormDirty: false,
          isFormEmpty: true,
          isHomeWithNoServicesEnabled: false,
          facilityError: "",
          additionalNotes: selectedToc?.additionalNote,
        })
      );
      dispatch(setCarePlanData(originalCarePlanData));
      setSelectedCarePlan("-1");
    }
  };

  return (
    <TransitionOfCarePresentation
      hasScrollBar={hasScrollBar}
      reasonModalPayload={reasonModalPayload}
      featureFlags={featureFlags}
      isNewTocExists={isNewTocExists}
      patientDetails={patientDetails}
      isTocsLoading={isTocsLoading}
      openReasonModal={openReasonModal}
      dropdownRef={ref}
      updateState={updateState}
      getTocsList={getTocsList}
      patientDetailsDisplay={patientDetailsDisplay}
      handleSubmitClick={handleSubmitClick}
      selectedToc={selectedToc}
      resetFormDetails={resetFormDetails}
      onClickCustomisedCarePlan={onClickCustomisedCarePlan}
    />
  );
};

export default TransitionOfCareContainer;
