import { useDispatch, useSelector } from "react-redux";
import TocReasonModalPresentation from "./toc-reason-modal.component";
import "./toc-reason-modal.styles.scss";
import { setIsOpenTocReasonModal } from "state/feature/common/common.slice";
import { getTOCManagementState, setTocCustomiseReason } from "state/feature/toc-management/toc-management.slice";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { useState } from "react";

interface TocReasonModalProps {
  onClickCustomisedCarePlan: () => void;
}

const TocReasonModal = (props: TocReasonModalProps) => {
  const dispatch = useDispatch();
  const { tocCustomiseReason } = useSelector(getTOCManagementState);
  const { onClickCustomisedCarePlan } = props;
  const [showError, setShowError] = useState(false);

  const closeTocReasonModal = () => {
    dispatch(setTocCustomiseReason([]));
    dispatch(setIsOpenTocReasonModal(false));
  };

  const handleCustomiseError = (value: boolean) => {
    setShowError(value);
  };

  const handleSubmit = () => {
    if (tocCustomiseReason.length > 0) {
      onClickCustomisedCarePlan();
      dispatch(setIsOpenTocReasonModal(false));
    } else {
      handleCustomiseError(true);
    }
  };

  return (
    <TocReasonModalPresentation
      closeTocReasonModal={closeTocReasonModal}
      handleSubmit={handleSubmit}
      showError={showError}
      handleCustomiseError={handleCustomiseError}
    />
  );
};
export default TocReasonModal;
