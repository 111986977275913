import getHighlightedText from "components/HighLightedText/high-lighted-text.component";
import Icon from "components/Icon/icon.component";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { checkIsArrayEmpty } from "shared/methods/utilityFunctions";
import TickmarkIcon from "shared/assets/images/tickmark.png";
import { DropdownComponentProps } from "./single-select-dropdown-with-clear-option.props";
import "./single-select-dropdown-with-clear-option.styles.scss";
import { isStringEmpty } from "shared/methods/taskSpecificMethods";

const SingleSelectDropdownWithClearOption = (props: DropdownComponentProps) => {
  const {
    dropDownMenuItems,
    isDisabled,
    isError,
    clearSelection,
    onItemSelected,
    isClearIconRequired = true,
    value,
    placeholder = "Select",
    emptyMessaage = "No results found",
    searchPlaceholder = "Search",
    dropDownBoxClass = "",
    noSearchBar = false,
  } = props;
  const [isOptionsVisible, setIsOptionsVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement | null>();

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const getSearchResults = useMemo(
    () => dropDownMenuItems.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())),
    [search, dropDownMenuItems]
  );

  const setVisibilityOfOption = () => {
    setIsOptionsVisible(false);
    setSearch("");
  };

  const cancelSelected = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isDisabled) {
      return;
    }
    if (clearSelection) clearSelection(value);
  };

  const onOptionClick = (key: number | string) => {
    if (!checkIsArrayEmpty(dropDownMenuItems)) {
      const result = dropDownMenuItems.find((item) => item.key === key)!;
      onItemSelected(result);
      setVisibilityOfOption();
    }
  };

  const openScrollView = () => {
    if (dropdownRef.current) dropdownRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
  };

  useEffect(() => {
    if (isOptionsVisible || isStringEmpty(search)) openScrollView();
  }, [isOptionsVisible, search]);

  const onBlurDropdown = (e: any) => {
    const isClickedOnDropdownOptions = e.relatedTarget
      ? Object.values(e.relatedTarget.classList).includes("search-input")
      : false;
    if (!isClickedOnDropdownOptions) {
      setIsOptionsVisible(false);
    }
  };

  return (
    <div className={`dropdown-container ${dropDownBoxClass}`} tabIndex={0} onBlur={onBlurDropdown}>
      <div
        className={`current-selction-container ${isDisabled ? "disabled" : ""} ${!isError ? "" : "input-error"}`}
        onClick={(e: any) => {
          const crossIconPresent = e.target ? Object.values(e.target.classList).includes("search-cross-icon") : false;
          if (isDisabled) {
            return;
          }
          if (!crossIconPresent) {
            setIsOptionsVisible(!isOptionsVisible);
          }
        }}
      >
        {value && value?.key !== -1 && value.name.length ? (
          <div className="current-selection">{value?.name}</div>
        ) : (
          <div className="placeholder">{placeholder}</div>
        )}

        {value.key && value?.key !== -1 && isClearIconRequired ? (
          <div className="cancel-selection-container">
            <div className="cancel-icon-container" onClick={cancelSelected}>
              <Icon
                icon="cross-in-circle"
                size={18}
                className={`search-cross-icon ${isDisabled ? "search-cross-icon-disabled" : ""}`}
              />
            </div>
          </div>
        ) : null}
        <div className={`dropdown-icon-container ${isDisabled ? "dropdown-icon-container-disabled" : ""}`}>
          {isOptionsVisible ? (
            <Icon icon="up-arrow" className="select-box-up-arrow" size={11} />
          ) : (
            <Icon icon="down-arrow" className={`select-box-down-arrow `} size={11} />
          )}
        </div>
      </div>
      {isOptionsVisible ? (
        <div className="options-container" ref={(ref) => (dropdownRef.current = ref)}>
          {!noSearchBar && (
            <div className="search-container">
              <div className="search-input-container">
                <Icon icon="search-lens" size={16} className="search-lens-icon" />
                <input
                  className="search-input"
                  type="text"
                  value={search}
                  onChange={onSearchChange}
                  name="search"
                  key="search"
                  placeholder={searchPlaceholder}
                />
              </div>
              {search && search.length ? (
                <div className="cancel-icon-container">
                  <span onClick={() => setSearch("")}>
                    <Icon icon="search-cross" size={16} className="search-cross-icon" />
                  </span>
                </div>
              ) : null}
            </div>
          )}
          {!checkIsArrayEmpty(getSearchResults) ? (
            <div className="dropdown-options-container">
              {getSearchResults.map((item) => (
                <div className={`dropdown-options-row`} key={item.key} onMouseDown={() => onOptionClick(item.key)}>
                  <div className="name">{getHighlightedText(`${item.name}`, search)}</div>
                  <div className="distance">
                    {item.key === value?.key ? <img className="check" src={TickmarkIcon} alt="tick-mark" /> : null}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="dropdown-options-container">
              <div className="no-option-row" key="no-option">
                <div className="name">{emptyMessaage}</div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SingleSelectDropdownWithClearOption;
