import React, { useState, useEffect } from "react";
import "./custom-dropdown.styles.scss";
import ClickAwayListener from "react-click-away-listener";
import Icon from "components/Icon/icon.component";
import { CustomDropDownProps } from "./props";
import { isEmptyString } from "shared/methods/utilityFunctions";

const CustomDropDown = (props: CustomDropDownProps) => {
  const {
    placeholder,
    idFieldName,
    handleToastDismiss,
    dropDownMenuItems,
    handleValueChanges,
    value,
    itemClassName,
    optionClassName,
    dropDownContainerClass,
    dropDownBoxClass,
    selectionClass,
    isDisabled,
    isError,
    message,
    onBlur,
    activeOptionBasedOnValue,
    handleOpenDropdown,
    dropdownRef,
    defaultValue,
  } = props;
  const [activeOption, setActiveOption] = useState<any>({
    name: "",
  });
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);

  useEffect(() => {
    if (defaultValue && !isEmptyString(defaultValue.name)) {
      setActiveOption(defaultValue);
    }
  }, [defaultValue]);

  const handleDropdownChange = (state: any) => {
    if (handleToastDismiss) handleToastDismiss();
    setShowOptionDropdown((prev) => {
      const arrowEl = document.getElementById("select-box-down-arrow");
      handleOpenDropdown?.();
      if (prev) {
        arrowEl?.classList.remove("select-box-down-arrow-rotate");
        arrowEl?.classList.add("select-box-down-arrow-unrotate");
        return !prev;
      } else {
        if (state) {
          arrowEl?.classList.remove("select-box-down-arrow-unrotate");
          arrowEl?.classList.add("select-box-down-arrow-rotate");
          return !prev;
        } else return false;
      }
    });
  };

  const handleSelect = (selectedOption: any) => {
    if (activeOptionBasedOnValue && value) {
      setActiveOption(value);
    } else {
      setActiveOption(selectedOption);
    }
    handleValueChanges(selectedOption);
  };

  const handleOptionChange = (option: any) => {
    if (handleSelect) handleSelect(option);
    handleDropdownChange(false);
  };

  const onBlurDropdown = (e: any) => {
    const isClickedOnDropdownOptions = e.relatedTarget
      ? Object.values(e.relatedTarget.classList).includes("option-box-wrapper") ||
        Object.values(e.relatedTarget.classList).includes("option-box-wrapper-ul")
      : false;
    if (!isClickedOnDropdownOptions) {
      handleDropdownChange(false);
    }
  };

  useEffect(() => {
    if (showOptionDropdown && dropdownRef?.current) {
      dropdownRef.current.scrollIntoView({ block: "nearest", behavior: "smooth" });
    }
  }, [showOptionDropdown, dropdownRef]);

  useEffect(() => {
    setActiveOption(value);
  }, [value]);

  useEffect(() => {
    if (isDisabled) {
      setShowOptionDropdown(!isDisabled);
    }
  }, [isDisabled]);

  return (
    <div
      className={`dropdown-container ${dropDownContainerClass ? dropDownContainerClass : ""}`}
      tabIndex={0}
      onBlur={onBlurDropdown}
    >
      <span
        onBlur={onBlur ? onBlur : () => {}}
        className={`select-span ${isError ? "error" : "no-error"} ${selectionClass ? selectionClass : ""} ${
          isDisabled ? "no-pointer-events" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (!isDisabled) {
            handleDropdownChange(true);
          }
        }}
      >
        <div className="name-container">
          {activeOption?.name ? (
            <p className="selected-value">{activeOption?.name}</p>
          ) : (
            <p className="selected-value placeholder">{placeholder}</p>
          )}
        </div>
        <div className="icon-container">
          {showOptionDropdown ? (
            <Icon icon="up-arrow" size={11} />
          ) : (
            <Icon icon="down-arrow" className="select-box-down-arrow" size={11} />
          )}
        </div>
      </span>
      {isError && !showOptionDropdown && <div className="error-color">{message}</div>}
      {showOptionDropdown && (
        <div className={`option-box-wrapper ${dropDownBoxClass}`} ref={dropdownRef}>
          <ul className="option-box-wrapper-ul">
            {dropDownMenuItems.length > 0 &&
              dropDownMenuItems?.map((option: any, key) => (
                <li
                  key={key}
                  className={`${itemClassName ? itemClassName : ""}
										${activeOption && activeOption[idFieldName] === option[idFieldName] ? "active-option" : ""} ${
                    option?.disabled ? "disabled-option" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!option?.disabled) {
                      handleOptionChange(option);
                    }
                  }}
                >
                  {activeOption && activeOption[idFieldName] === option[idFieldName] ? (
                    <p className={`option selected ${optionClassName ? optionClassName : ""}`}>{option.name}</p>
                  ) : (
                    <p className={`option ${optionClassName ? optionClassName : ""}`}>{option.name}</p>
                  )}
                </li>
              ))}
            {dropDownMenuItems.length === 0 && (
              <>
                <li className={"disabled-option"}>
                  <p className={`option`}>No records found</p>
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
