import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { setIsOpenPatientStatusModal } from "state/feature/common/common.slice";
import { useAppDispatch } from "state/store";
import { isEmptyString } from "shared/methods/utilityFunctions";
import PatientStatusModalPresentation from "./patient-status-modal.component";
import {
  getEpisodeDetailsById,
  getIntakeStatuses,
  getPatientStatusById,
  getStatusChangeReason,
  postPatientEpisodeStatus,
} from "state/feature/episodes/episodes.action";
import {
  getEpisodesSlice,
  resetPatientStatus,
  setPatientEpisodeStatus,
  setStatusChangeReason,
} from "state/feature/episodes/episodes.slice";
import { ReasonStatus } from "state/types/episodes-slice.type";
import { PatientStatusDropdown } from "shared/enums/patient-status.enum";
import { careUtilizationEpisodeStatuses } from "./patient-status-modal-constants";
import { isPatientDeceasedOrDischarged } from "shared/methods/taskSpecificMethods";

const PatientStatusModal = () => {
  const dispatch = useAppDispatch();
  const { patientEpisodeStatus, patientEpisodeDetails, episodeDetails } = useSelector(getEpisodesSlice);
  const { intakeId } = useParams<{ intakeId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState(true);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);
  const [notes, setNotes] = useState("");
  const [careUtilizationValue, setCareUtilizationValue] = useState(false);

  const handleOnCancel = () => {
    dispatch(setIsOpenPatientStatusModal(false));
    setIsDisabledSubmitButton(true);
    setIsSubmitButtonPressed(false);
    dispatch(resetPatientStatus());
  };

  const getIsDisabledSubmitButton = () => {
    if (patientEpisodeStatus.statusChangeReasons.length > 0) {
      return (
        !isEmptyString(patientEpisodeStatus.intakeStatus.name) &&
        !isEmptyString(patientEpisodeStatus.statusChangeReason.name)
      );
    }
    return !isEmptyString(patientEpisodeStatus.intakeStatus.name);
  };

  const handleOnSubmit = () => {
    setIsSubmitButtonPressed(true);
    if (getIsDisabledSubmitButton()) {
      setIsLoading(true);
      dispatch(
        postPatientEpisodeStatus({
          id: parseInt(intakeId),
          intakeStatusID: patientEpisodeStatus.intakeStatus.key,
          intakeNotes: notes,
          statusChangeReasonID:
            patientEpisodeStatus.statusChangeReason.key !== -1 ? patientEpisodeStatus.statusChangeReason.key : 0,
          intakeStatusLongName: patientEpisodeStatus.intakeStatus.name,
          isPlanOfCareValidated: careUtilizationValue,
        })
      ).then((response: any) => {
        setIsLoading(false);
        if (response.payload.statusCode === 200) {
          dispatch(getEpisodeDetailsById({ intakeId: intakeId }));
          handleOnCancel();
        }
      });
    }
  };

  const handleChangeNote = (value: string) => {
    setIsDisabledSubmitButton(false);
    setNotes(value);
  };

  const handleChange = (
    optionName: string,
    optionValue: { [name: string]: any; name: string; key?: string | undefined }
  ) => {
    setIsDisabledSubmitButton(false);
    switch (optionName) {
      case PatientStatusDropdown.EPISODE_STATUS:
        if (isPatientDeceasedOrDischarged(optionValue.name)) {
          setCareUtilizationValue(false);
        }
        dispatch(setPatientEpisodeStatus(optionValue));
        if (optionValue.key !== undefined) {
          dispatch(setStatusChangeReason({ name: "", key: -1 }));
        }
        break;
      case PatientStatusDropdown.STATUS_CHANGE:
        dispatch(setStatusChangeReason(optionValue));
        break;
      default:
        break;
    }
  };

  const onChangeCareUtilization = (event: any) => {
    setCareUtilizationValue(event.target.checked);
    setIsDisabledSubmitButton(false);
  };

  useEffect(() => {
    setNotes(patientEpisodeStatus.statusChangeNote);
  }, [patientEpisodeStatus.statusChangeNote]);

  useEffect(() => {
    dispatch(getPatientStatusById({ intakeId })).then(() => {
      dispatch(getIntakeStatuses());
    });
  }, [intakeId, dispatch]);

  useEffect(() => {
    if (!isEmptyString(patientEpisodeStatus.intakeStatus.name)) {
      if (
        (patientEpisodeStatus.intakeStatus.name === careUtilizationEpisodeStatuses.DECEASED ||
          patientEpisodeStatus.intakeStatus.name === careUtilizationEpisodeStatuses.DISCHARGED) &&
        episodeDetails
      ) {
        setCareUtilizationValue(episodeDetails.isPlanOfCareValidated);
      }
      dispatch(getStatusChangeReason(patientEpisodeStatus.intakeStatus.key.toString())).then((items) => {
        if (patientEpisodeDetails && patientEpisodeDetails.statusChangeReasonId > 0) {
          const reason = items.payload.find(
            (item: ReasonStatus) => item.id === patientEpisodeDetails?.statusChangeReasonId
          );
          if (reason) {
            dispatch(setStatusChangeReason({ key: reason.id, name: reason.name }));
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientEpisodeStatus.intakeStatus.name]);

  return (
    <PatientStatusModalPresentation
      handleOnSubmit={handleOnSubmit}
      handleChange={handleChange}
      handleChangeNote={handleChangeNote}
      handleOnCancel={handleOnCancel}
      isDisabledSubmitButton={isDisabledSubmitButton}
      isLoading={isLoading}
      isSubmitButtonPressed={isSubmitButtonPressed}
      notes={notes}
      onChangeCareUtilization={onChangeCareUtilization}
      careUtilizationValue={careUtilizationValue}
    />
  );
};

export default PatientStatusModal;
