import { useSelector } from "react-redux";
import "./patient-and-episode-details.styles.scss";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { isEmptyString } from "shared/methods/utilityFunctions";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import useFooterVisibility from "../../../../shared/hooks/use-footer-visibility";
import Collapsible from "./components/collapsible/collapsible.component";
import AlertCollapsible from "./components/alert-collapsible/alert-collapsible.component";
import { useAppDispatch } from "state/store";
import { getPlanOfCareListAsync } from "state/feature/episodes/episodes.action";
import { resetPlanofCareList } from "state/feature/episodes/episodes.slice";
import { IPlanOfCareListType } from "pages/episodes/episode-details/tabs/plan-of-care/type";
import ReadmissionCollapsible from "./components/readmission-collapsible/readmission-collapsible.component";
import PatientDeclineNavigation from "components/patient-decline-navigation-alert/patient-decline-navigation-alert.component";

const PatientAndEpisodeDetails = () => {
  useFooterVisibility();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { currentTab } = useSelector(getBrowserState);
  const { intakeId } = currentTab!;
  const { episode, isRightPanelLoading, patientDetails } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const [risk, setRisk] = useState("");
  const [highRiskSectionList, setHighRiskSectionList] = useState<string[]>([]);
  const [highRiskSections, setHighRiskSections] = useState(0);
  const [isReadmissionOpen, setIsReadmissionOpen] = useState(false);
  const [readmissionList, setReadmissionList] = useState([]);
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (
      typeof episode?.statusAndAlerts.riskLevel === "object" &&
      episode?.statusAndAlerts.riskLevel &&
      episode?.statusAndAlerts.riskLevel.length > 0
    ) {
      episode?.statusAndAlerts.riskLevel.forEach((sec) => {
        if (sec.score >= sec.riskScore) {
          setHighRiskSectionList((prev) => {
            const copy = new Set([...prev]);
            copy.add(sec.section);
            return Array.from(copy);
          });
          setHighRiskSections((count) => count + 1);
        }
      });
    }
  }, [episode?.statusAndAlerts]);

  const ifPresentAndNotEmpty = (value: string | undefined) => {
    return episode?.patientAndEpisodeDetails && value !== undefined && !isEmptyString(value);
  };

  const getProcedureDetails = () => {
    let procedureDetails = "";
    if (ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.episodeType)) {
      procedureDetails += episode?.patientAndEpisodeDetails.episodeType;
    }
    const surgerySiteExist = ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.surgerySite);
    if (surgerySiteExist) {
      procedureDetails += `, ${episode?.patientAndEpisodeDetails.surgerySite}`;
    }
    if (ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.surgerySide)) {
      procedureDetails += `${surgerySiteExist ? "-" : " "}${episode?.patientAndEpisodeDetails.surgerySide}`;
    }
    return procedureDetails;
  };

  const getRiskLevel = useCallback(() => {
    if (typeof episode?.statusAndAlerts.riskLevel === "string") {
      return episode.statusAndAlerts?.riskLevel;
    } else if (typeof episode?.statusAndAlerts.riskLevel === "object") {
      return highRiskSections > 0 || !patientDetails?.isPlannedEpisode ? "High" : "Standard";
    } else {
      return "-";
    }
  }, [episode?.statusAndAlerts, highRiskSections, patientDetails?.isPlannedEpisode]);

  useEffect(() => {
    if (episode?.statusAndAlerts.riskLevel !== undefined) {
      setRisk(getRiskLevel());
    }
  }, [episode?.statusAndAlerts, episode?.statusAndAlerts.riskLevel, getRiskLevel]);

  useEffect(() => {
    if (episode?.statusAndAlerts.alert && episode?.statusAndAlerts.alert.length > 0) {
      setIsAlertOpen(true);
    } else {
      setIsAlertOpen(false);
    }
  }, [episode?.statusAndAlerts.alert]);

  useEffect(() => {
    if (intakeId) {
      appDispatch(getPlanOfCareListAsync({ intakeId })).then((res) => {
        if (res.payload && res.payload.length) {
          const newReadmissionList = res.payload.filter(
            (poc: IPlanOfCareListType) =>
              poc.pacType &&
              ["OP-Procedure-Surgery", "ER-Urgent Care", "Hospital-Observation", "Hospital-Readmit"].includes(
                poc.pacType
              )
          );
          setIsReadmissionOpen(newReadmissionList.length > 0 ? true : false);
          setReadmissionList(newReadmissionList);
        }
      });
    }
  }, [appDispatch, intakeId]);

  useEffect(() => {
    return () => {
      appDispatch(resetPlanofCareList());
    };
  }, [appDispatch]);

  const getOffTrackDate = () => {
    if (episode?.statusAndAlerts?.offTrack) {
      return moment(episode?.statusAndAlerts?.offTrack).format("MM/DD/YYYY");
    } else {
      return "-";
    }
  };

  const getRiskType = () => {
    if (!patientDetails?.isPlannedEpisode && risk === "Incomplete") {
      return (
        <div>
          High<span className="risk-when-unplanned"> - {risk}</span>
        </div>
      );
    } else {
      return risk;
    }
  };

  return (
    <div className="task-container-tab">
      {isRightPanelLoading ? (
        <div className="loading-container">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      ) : (
        <>
          {episode?.patientAndEpisodeDetails.patientNavigationDeclined && (
            <PatientDeclineNavigation
              showNotesIcon={true}
              patientNavigationDeclinedNotes={episode?.patientAndEpisodeDetails?.patientNavigationDeclinedNotes}
              className="task-info-patient-decline-alert"
              patientNavigationDeclinedChanged={episode?.patientAndEpisodeDetails?.patientNavigationDeclinedChanged}
            />
          )}
          <Collapsible
            isOpen={isAlertOpen}
            handleClick={(val) => {
              setIsAlertOpen(!val);
            }}
            heading="Alert"
            containerClass={"alert-collapsible-class-container"}
            isTriggerDisabled={
              episode &&
              episode?.statusAndAlerts &&
              episode?.statusAndAlerts.alert &&
              episode?.statusAndAlerts.alert.length > 0
                ? false
                : true
            }
            content={<AlertCollapsible />}
          />

          <Collapsible
            isOpen={isReadmissionOpen}
            handleClick={(val) => {
              setIsReadmissionOpen(!val);
            }}
            heading="Readmissions"
            containerClass={"readmission-collapsible-class-container"}
            isTriggerDisabled={readmissionList.length === 0}
            content={<ReadmissionCollapsible readmissionList={readmissionList} />}
          />
          <div className="heading">Patient & Episode Details</div>
          <div className="task-container-tab-info">
            <div className="title">Risk</div>
            <div className="value">
              <div className={`${highRiskSections > 0 || !patientDetails?.isPlannedEpisode ? "risk-level" : ""}`}>
                {isEmptyString(risk) ? "-" : getRiskType()}
              </div>
              {highRiskSections > 0 && <div> {highRiskSectionList.join(",")}</div>}
              {!patientDetails?.isPlannedEpisode && <div>Unplanned</div>}
            </div>
            <div className="title">Off-track</div>
            <div className="value">
              <div className={`${episode?.statusAndAlerts?.status === "OnTrack" ? "" : "off-track"}`}>
                {episode?.statusAndAlerts &&
                episode?.statusAndAlerts?.status &&
                episode?.statusAndAlerts?.status === "OnTrack"
                  ? "No"
                  : getOffTrackDate()}
              </div>
              {episode?.statusAndAlerts?.status !== "OnTrack" && (
                <div className="reason">{episode?.statusAndAlerts?.riskInfo}</div>
              )}
            </div>
            <div className="title">Location</div>
            <div className="value">
              {ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.location)
                ? episode?.patientAndEpisodeDetails.location
                : "-"}
            </div>
            <div className="title">Coach</div>
            <div className="value">
              {ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.coach)
                ? episode?.patientAndEpisodeDetails.coach
                : "N/A"}
            </div>
            <div className="title">Episode</div>
            <div className="value">
              {ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.episode)
                ? episode?.patientAndEpisodeDetails.episode
                : "-"}
            </div>
            <div className="title">Procedure</div>
            <div className="value">
              {ifPresentAndNotEmpty(episode?.patientAndEpisodeDetails.procedure)
                ? episode?.patientAndEpisodeDetails.procedure
                : "N/A"}
            </div>
            <div className="title">Procedure Details</div>
            <div className="value">{isEmptyString(getProcedureDetails()) ? "-" : getProcedureDetails()}</div>
            {episode?.patientAndEpisodeDetails.admitDate && (
              <>
                <div className="title">Admission Date</div>
                <div className="value">{moment(episode?.patientAndEpisodeDetails.admitDate).format("MM/DD/YYYY")}</div>
              </>
            )}
            {episode?.patientAndEpisodeDetails.surgeryDate &&
              episode?.patientAndEpisodeDetails.episodeCare &&
              episode?.patientAndEpisodeDetails.episodeCare.toLowerCase() !== "medical" && (
                <>
                  <div className="title">Procedure Date</div>
                  <div className="value">
                    {moment(episode?.patientAndEpisodeDetails.surgeryDate).format("MM/DD/YYYY")}
                  </div>
                </>
              )}
            {episode?.patientAndEpisodeDetails.programDischargeDate && (
              <>
                <div className="title">Prog. Discharge Date</div>
                <div className="value">
                  {moment(episode?.patientAndEpisodeDetails.programDischargeDate).format("MM/DD/YYYY")}
                </div>
              </>
            )}
            <div className="title">Physician</div>
            <div className="value">
              {episode?.patientAndEpisodeDetails.physician &&
              !isEmptyString(episode?.patientAndEpisodeDetails.physician)
                ? episode?.patientAndEpisodeDetails.physician
                : "N/A"}
            </div>
            <div className="title">Practice</div>
            <div className="value">
              {episode?.patientAndEpisodeDetails.practice && !isEmptyString(episode?.patientAndEpisodeDetails.practice)
                ? episode?.patientAndEpisodeDetails.practice
                : "N/A"}
            </div>
            <div className="title">Care Plan {episode?.patientAndEpisodeDetails.latestTocVersion}</div>
            <div className="value">
              {episode?.patientAndEpisodeDetails.toc && Object.keys(episode?.patientAndEpisodeDetails.toc).length > 0
                ? Object.entries(episode?.patientAndEpisodeDetails.toc).map(([toc, visits]) => {
                    return (visits as number) > 0 ? (
                      <div>
                        {toc} - {visits} {toc === "IRF" || toc === "SNF" ? "Days" : "Visits"}
                      </div>
                    ) : null;
                  })
                : "-"}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientAndEpisodeDetails;
